<template>
  <div>
    <slot name="actions" :onOpenCheckout="onOpenCheckout" />
    <CheckoutModal
      v-if="showCheckoutModal"
      v-model="isModalOpen"
      :title="title"
      :checkout="selectedCheckout"
      :operation="operation"
      @ok="manageCheckout"
    />

    <OperationsModal
      v-if="showOperationsModal"
      v-model="isOperationsModalOpen"
      :checkout="selectedCheckout"
      :type="operationTypes"
      @close="closeOperationsModal"
    />

    <template v-if="!hideWarnings">
      <b-alert v-if="checkouts.error" show variant="danger">
        <span class="fas fa-exclamation-triangle mr-1" /> {{ checkouts.error }}
      </b-alert>

      <b-alert v-if="hasOpenCheckout" show variant="warning">
        <span class="fas fa-exclamation-triangle mr-1" /> Para poder abrir um
        novo caixa é preciso fechar o caixa atual.
      </b-alert>
    </template>

    <!-- <b-alert v-if="hasPreviousCheckout" show variant="warning">
      <span class="fas fa-exclamation-triangle mr-1" /> O caixa do dia anterior
      ainda está aberto. Feche-o para poder abrir um novo caixa.
    </b-alert> -->

    <b-table
      ref="checkoutsList"
      :fields="fields"
      :items="checkouts.data"
      striped
      hover
      show-empty
      :busy="checkouts.loading"
    >
      <div slot="empty" class="text-center">Nenhum caixa registrado</div>
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="getColumnStyle(field.key)"
        />
      </template>
      <template #cell(opening)="{ item }">
        <div>
          <span class="d-block">
            <b>{{ item.createdAt | formatDateTime }}</b>
          </span>
          <span class="d-block">
            <b>Valor inicial:</b> {{ item.openingValue | formatCurrency }}
          </span>
          <span class="d-block">
            <b>Qtde Produtos:</b> {{ item.openingProductCount }}
          </span>
          <small class="text-muted">
            <b>Aberto por:</b> {{ item.createdByUser.name }}
          </small>
        </div>
      </template>
      <template #cell(closing)="{ item }">
        <div v-if="item.closedAt">
          <span class="d-block">
            <b>{{ item.closedAt | formatDateTime }}</b>
          </span>
          <span class="d-block">
            <b>Valor final:</b> {{ item.closingValue | formatCurrency }}
          </span>
          <span class="d-block">
            <b>Qtde Produtos:</b> {{ item.closingProductCount }}
          </span>
          <small v-if="item.closedByUser" class="text-muted">
            <b>Fechado por:</b> {{ item.closedByUser.name }}
          </small>
        </div>
        <span v-else>-</span>
      </template>
      <template #cell(status)="{ item }">
        <Status :value="getStatus(item)" />
      </template>
      <template #cell(actions)="{ item }">
        <div class="d-flex gap-1">
          <b-button
            size="sm"
            class="d-flex align-items-center justify-content-center"
            variant="dark"
            @click="openCheckoutOperationsModal(item)"
          >
            <span class="fas fa-eye mr-1" />
            <span class="d-none d-md-block d-lg-block"> Lançamentos </span>
          </b-button>
          <b-button
            v-if="canCloseCheckout(item)"
            size="sm"
            class="d-flex align-items-center justify-content-center"
            variant="primary"
            @click="onCloseCheckout(item)"
          >
            <span class="fas fa-calculator mr-1" />
            <span class="d-none d-md-block d-lg-block"> Fechar </span>
          </b-button>
          <b-button
            v-if="isReport"
            size="sm"
            class="d-flex align-items-center justify-content-center"
            variant="primary"
            @click="onViewCheckout(item)"
          >
            <span class="fas fa-eye mr-1" />
            <span class="d-none d-md-block d-lg-block"> Ver Detalhes </span>
          </b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  CHECKOUT_OPERATIONS,
  CHECKOUT_STATUS,
  OPERATION_TYPES
} from '@/constants'

import { isSameDay } from 'date-fns'
import services from '@/services'
import { formatCurrency } from '@/helpers/numbers'
import { parseCheckout } from '@/helpers/checkouts'
import { formatDate, formatDateTime } from '@/helpers/date'

import Status from '@/components/Status'
import CheckoutModal from './CheckoutModal'
import OperationsModal from './OperationsModal'

export default {
  name: 'CheckoutsList',
  filters: {
    formatCurrency,
    formatDate,
    formatDateTime
  },
  components: {
    Status,
    CheckoutModal,
    OperationsModal
  },
  props: {
    checkouts: {
      type: Object,
      required: true
    },
    hideWarnings: {
      type: Boolean
    },
    isReport: {
      type: Boolean
    },
    readonly: {
      type: Boolean
    }
  },
  data() {
    return {
      title: '',
      operation: '',
      isModalOpen: false,
      isBusy: false,
      error: null,
      isOperationsModalOpen: false,
      selectedCheckout: null,
      filter: {
        storeId: null,
        date: null
      }
    }
  },
  computed: {
    fields: () => [
      {
        key: 'opening',
        label: 'Abertura'
      },
      {
        key: 'closing',
        label: 'Fechamento'
      },
      {
        key: 'status',
        label: 'Status'
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ],
    filteredCheckouts() {
      return this.checkouts.data.filter(({ closedAt }) => !closedAt)
    },
    hasOpenCheckout() {
      return this.checkouts.data.some(({ closedAt }) => !closedAt)
    },
    hasOldCheckout() {
      isSameDay
      return false
      // return this.checkouts.some(
      //   ({ createdAt, closedAt }) =>
      //     !isSameDay(new Date(createdAt), new Date()) || !closedAt
      // )
    },
    showOperationsModal() {
      return (
        this.selectedCheckout &&
        this.operation === CHECKOUT_OPERATIONS.OPERATIONS
      )
    },
    showCheckoutModal() {
      const { OPEN, CLOSE } = CHECKOUT_OPERATIONS

      const isOpeningCheckout = this.operation === OPEN
      const isClosingCheckout =
        this.selectedCheckout && this.operation === CLOSE

      return isOpeningCheckout || isClosingCheckout
    },
    operationTypes() {
      return Object.values(OPERATION_TYPES)
    }
  },
  methods: {
    getColumnStyle(field) {
      return { width: field === 'actions' ? '25%' : '' }
    },
    getStatus(checkout) {
      return checkout.closedAt
        ? CHECKOUT_STATUS.CLOSED.value
        : CHECKOUT_STATUS.OPEN.value
    },
    async manageCheckout(checkout) {
      if (this.operation === CHECKOUT_OPERATIONS.OPEN) {
        await this.openCheckout(checkout)
      } else {
        await this.closeCheckout(checkout)
      }
    },
    async openCheckout(checkout) {
      await services.checkouts.openCheckout({
        ...parseCheckout({ ...this.selectedCheckout }),
        ...parseCheckout(checkout)
      })

      this.isModalOpen = false
      this.$root.$toast.success('Caixa aberto com sucesso')
      this.$emit('refresh')
    },
    async closeCheckout(checkout) {
      await services.checkouts.closeCheckout({
        ...this.selectedCheckout,
        ...parseCheckout(checkout)
      })

      this.isModalOpen = false
      this.selectedCheckout = null
      this.$root.$toast.success('Caixa fechado com sucesso')
      this.$emit('refresh')
    },
    onOpenCheckout() {
      this.title = 'Abrir Novo Caixa'
      this.operation = CHECKOUT_OPERATIONS.OPEN
      this.selectedCheckout = null
      this.isModalOpen = true
    },
    onCloseCheckout(checkout) {
      this.title = 'Fechar Caixa'
      this.operation = CHECKOUT_OPERATIONS.CLOSE
      this.selectedCheckout = checkout
      this.isModalOpen = true
    },
    onViewCheckout(checkout) {
      this.$router.push({
        name: 'ReportCheckoutDetails',
        params: { id: checkout.id }
      })
    },
    openCheckoutOperationsModal(checkout) {
      this.selectedCheckout = checkout
      this.operation = CHECKOUT_OPERATIONS.OPERATIONS
      this.$nextTick(() => (this.isOperationsModalOpen = true))
    },
    closeOperationsModal() {
      this.selectedCheckout = null
      this.operation = null
    },
    canCloseCheckout(item) {
      return !item.closedAt && !this.isReport && !this.readonly
    }
  }
}
</script>
