var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("actions",null,{"onOpenCheckout":_vm.onOpenCheckout}),(_vm.showCheckoutModal)?_c('CheckoutModal',{attrs:{"title":_vm.title,"checkout":_vm.selectedCheckout,"operation":_vm.operation},on:{"ok":_vm.manageCheckout},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}}):_vm._e(),(_vm.showOperationsModal)?_c('OperationsModal',{attrs:{"checkout":_vm.selectedCheckout,"type":_vm.operationTypes},on:{"close":_vm.closeOperationsModal},model:{value:(_vm.isOperationsModalOpen),callback:function ($$v) {_vm.isOperationsModalOpen=$$v},expression:"isOperationsModalOpen"}}):_vm._e(),(!_vm.hideWarnings)?[(_vm.checkouts.error)?_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('span',{staticClass:"fas fa-exclamation-triangle mr-1"}),_vm._v(" "+_vm._s(_vm.checkouts.error)+" ")]):_vm._e(),(_vm.hasOpenCheckout)?_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_c('span',{staticClass:"fas fa-exclamation-triangle mr-1"}),_vm._v(" Para poder abrir um novo caixa é preciso fechar o caixa atual. ")]):_vm._e()]:_vm._e(),_c('b-table',{ref:"checkoutsList",attrs:{"fields":_vm.fields,"items":_vm.checkouts.data,"striped":"","hover":"","show-empty":"","busy":_vm.checkouts.loading},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:(_vm.getColumnStyle(field.key))})})}},{key:"cell(opening)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"d-block"},[_c('b',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.createdAt)))])]),_c('span',{staticClass:"d-block"},[_c('b',[_vm._v("Valor inicial:")]),_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.openingValue))+" ")]),_c('span',{staticClass:"d-block"},[_c('b',[_vm._v("Qtde Produtos:")]),_vm._v(" "+_vm._s(item.openingProductCount)+" ")]),_c('small',{staticClass:"text-muted"},[_c('b',[_vm._v("Aberto por:")]),_vm._v(" "+_vm._s(item.createdByUser.name)+" ")])])]}},{key:"cell(closing)",fn:function(ref){
var item = ref.item;
return [(item.closedAt)?_c('div',[_c('span',{staticClass:"d-block"},[_c('b',[_vm._v(_vm._s(_vm._f("formatDateTime")(item.closedAt)))])]),_c('span',{staticClass:"d-block"},[_c('b',[_vm._v("Valor final:")]),_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.closingValue))+" ")]),_c('span',{staticClass:"d-block"},[_c('b',[_vm._v("Qtde Produtos:")]),_vm._v(" "+_vm._s(item.closingProductCount)+" ")]),(item.closedByUser)?_c('small',{staticClass:"text-muted"},[_c('b',[_vm._v("Fechado por:")]),_vm._v(" "+_vm._s(item.closedByUser.name)+" ")]):_vm._e()]):_c('span',[_vm._v("-")])]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('Status',{attrs:{"value":_vm.getStatus(item)}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex gap-1"},[_c('b-button',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"size":"sm","variant":"dark"},on:{"click":function($event){return _vm.openCheckoutOperationsModal(item)}}},[_c('span',{staticClass:"fas fa-eye mr-1"}),_c('span',{staticClass:"d-none d-md-block d-lg-block"},[_vm._v(" Lançamentos ")])]),(_vm.canCloseCheckout(item))?_c('b-button',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.onCloseCheckout(item)}}},[_c('span',{staticClass:"fas fa-calculator mr-1"}),_c('span',{staticClass:"d-none d-md-block d-lg-block"},[_vm._v(" Fechar ")])]):_vm._e(),(_vm.isReport)?_c('b-button',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.onViewCheckout(item)}}},[_c('span',{staticClass:"fas fa-eye mr-1"}),_c('span',{staticClass:"d-none d-md-block d-lg-block"},[_vm._v(" Ver Detalhes ")])]):_vm._e()],1)]}}])},[_c('div',{staticClass:"text-center",attrs:{"slot":"empty"},slot:"empty"},[_vm._v("Nenhum caixa registrado")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }